import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { formatInitialPath } from 'utils/formatPath';
import { useSelector } from 'react-redux';
import { selectInitialRoute } from 'store/routes/selectors';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const [user, loading] = useCurrentUser();
  const initialRoute = useSelector(selectInitialRoute);
  const redirectPath = formatInitialPath(initialRoute);

  if (user || loading) {
    return <Redirect to={`${redirectPath}${location.search}`} />;
  }

  return <Redirect to="/error" />;
};
