import React from 'react';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BookingStatus, ORDER_TYPES, TransactionDetailsServiceTypes } from 'shared/consts';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectCart } from 'store/cart/selectors';
import { selectTransaction, selectTransactionDisplay, selectTransactionStatus } from 'store/transactions/selectors';
import { formatDate } from 'utils/formatDate';
import { getReceiptInfo } from 'utils/getReceiptInfo';
import { getReceiptInfoFromTransaction } from 'utils/getReceiptInfoFromTransaction';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { IconBlockContainer, ServiceDetails, StyledSummarySubtitle, StyledTitleBlock } from './styles';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { useCalendarLinks } from 'hooks/use-calendar-links.hook';

interface ServiceDetailsSectionProps {
  isSwipeModalContent?: boolean;
}

const DEFAULT_SPACING = 12;

export const ServiceDetailsSection = ({ isSwipeModalContent = false }: ServiceDetailsSectionProps) => {
  const intl = useIntl();
  const cart = useSelector(selectCart);
  const locale = useLocale();
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const transaction = useSelector(selectTransaction);
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);
  const transactionStatus = useSelector(selectTransactionStatus);
  const service = useSelector(selectCurrentAvailableService);
  const isTransactionMode = !!transactionUuid;
  const currentData = isTransactionMode ? transactionDisplay : cart;
  const isTransactionCancelled = transactionStatus === BookingStatus.CANCELLED;
  const bookingStart = transactionUuid ? transaction?.details?.start_at : cart?.items[0]?.service_booking?.start_at;

  const { links } = useCalendarLinks({ isShowingLinks: !isTransactionCancelled, service });

  const { eventWeekDayLong, eventDateMonthLong, eventDateDay, eventDateYear } = formatDate(
    bookingStart,
    locale as string,
    buildingTimeZone,
  );

  const isServiceBookingEvent = cart
    ? cart.type === ORDER_TYPES.SERVICE_BOOKING_EVENT
    : transaction?.details?.service?.type === TransactionDetailsServiceTypes.EVENT;

  const vendorFormattedAddress = currentData?.vendor?.formatted_address;

  const vendorName = currentData?.vendor?.name;

  const mapProps = isServiceBookingEvent
    ? {
        title: service ? service.address : vendorFormattedAddress,
        subtitle: service ? service.directions : '',
      }
    : {
        title: vendorName ?? 'default_building_name',
        subtitle: vendorFormattedAddress ?? '',
      };

  const summarySubtitle = transactionUuid
    ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).title
    : getReceiptInfo(cart, locale as string, buildingTimeZone).title;

  const eventBlockTitle = currentData?.items[0]?.display_info?.title;
  const instructorName = currentData?.items[0].display_info.description1;

  return (
    <ServiceDetails isSwipeModalContent={isSwipeModalContent} data-testid="service-details-container">
      <StyledSummarySubtitle>
        {intl.formatMessage({
          id: summarySubtitle,
        })}
      </StyledSummarySubtitle>
      {!isServiceBookingEvent && (
        <StyledTitleBlock
          spacing={DEFAULT_SPACING}
          title={eventBlockTitle}
          subtitle={intl.formatMessage(
            { id: 'with_instructor' },
            {
              instructorName,
            },
          )}
        />
      )}
      <IconBlockContainer>
        <IconBlock
          icon="calendar"
          iconType="fal"
          title={`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}
          subtitle={
            transactionUuid
              ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).eventBlockSubtitle
              : getReceiptInfo(cart, locale as string, buildingTimeZone).eventBlockSubtitle
          }
          links={links}
        />
        <IconBlock
          icon="map-marker-alt"
          linkText={vendorFormattedAddress ? intl.formatMessage({ id: 'open_in_maps' }) : ''}
          linkRef={`https://www.google.com/maps?q=${vendorFormattedAddress}`}
          iconType="fal"
          title={mapProps.title}
          subtitle={mapProps.subtitle}
        />
      </IconBlockContainer>
    </ServiceDetails>
  );
};
